<template>
  <div>
    <b-btn v-if="tab.role !== 'User'" class="d-flex ml-auto mb-1" variant="primary" @click="addNewUser">
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span>เพิ่ม {{ tab.title }}</span>
    </b-btn>
    <div v-if="tab.role === 'User'" class="d-flex mb-50">
      <b-input
        v-model="paymentUUID"
        type="text"
        class="w-100 mr-50"
        placeholder="ระบุ UUID ผู้ใช้ทั่วไป เพื่อไปหน้าบัญชีของผู้ใช้"
      />
      <b-btn :to="{ name: 'userManagement-user-info', params: { userId: paymentUUID } }" variant="primary">
        ตกลง
      </b-btn>
    </div>
    <table-server-side-v-1-widget
      :ref="`ref-table-user-dynamic-role-${tab.role}`"
      :url="cUrl"
      :columns="cColumns"
      :actions="cActions"
      @clickViewUserDetail="row => $router.push({ name: 'userManagement-user-info', params: { userId: row.id } })"
      @clickEditDynamicRole="editUserDynamicRole"
      @clickDeleteDynamicRole="deleteUserDynamicRole"
    >
      <template #country_code="{row}">
        <div class="d-flex align-items-center justify-content-center">
          <b-img
            v-if="row.country_flags_url"
            :src="row.country_flags_url"
            :alt="`${row.country_code || '-'}`"
            class="mr-50"
            height="30"
          />
          <span>{{ row.country_code || '-' }}</span>
        </div>
      </template>
    </table-server-side-v-1-widget>

    <b-modal
      ref="ref-modal-dynamic-form-user-role"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="modalForm.id ? `แก้ไข ${tab.title}` : `เพิ่ม ${tab.title}`"
      @ok="addOrEdit"
      @cancel="cancelModalForm"
    >
      <validation-observer ref="ref-form-dynamic-user-role">
        <b-form-group label="อีเมล">
          <validation-provider #default="{ errors }" rules="required|email">
            <b-form-input v-model="modalForm.email" :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="ชื่อ">
          <validation-provider #default="{ errors }" rules="required">
            <b-form-input v-model="modalForm.user_profile_data.first_name" :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="สกุล">
          <validation-provider #default="{ errors }" rules="required">
            <b-form-input v-model="modalForm.user_profile_data.last_name" :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="รหัสผ่าน">
          <validation-provider #default="{ errors }" rules="required|min:8" name="Password">
            <b-form-input v-model="modalForm.password" type="password" :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="ยืนยันรหัสผ่าน">
          <validation-provider #default="{ errors }" rules="required|min:8|confirmed:Password" name="ยืนยันรหัสผ่าน">
            <b-form-input
              v-model="modalForm.password_confirmation"
              type="password"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver, ValidationProvider },
  props: {
    tab: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      paymentUUID: '',
      modalForm: {},
      defaultModalForm: {
        id: '',
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        role_data_id: '',
        user_profile_data: {
          first_name: '',
          last_name: '',
        },
      },
      roleIdEnumObj: {
        Admin: '7fa970cc-aa32-49bc-9cf6-50f996bc6385',
        Support: '6e5edbb0-f750-4c81-9669-81147585789f',
        Marketing: '1941d5f7-618c-40dc-a5f5-fa333d66ef16',
      },
    }
  },
  computed: {
    cUrl() {
      return `api/admin/user-data-table?role=${this.tab.role}`
    },
    cColumns() {
      const commonColumns = [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'email',
        },
        {
          label: 'ชื่อ สกุล',
          field: 'fullName',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
      ]

      if (this.tab.role === 'User') {
        return [
          {
            label: 'ประเทศ',
            field: 'country_code',
            type: 'custom',
            thClass: 'text-center',
            sortable: false,
          },
          ...commonColumns,
          {
            label: 'เครดิตคงเหลือ',
            field: 'credit_sandbox',
            type: 'currency',
            filterable: true,
            filterOptions: {
              enabled: true,
              placeholder: '',
            },
          },
          {
            label: 'สมัครจาก',
            field: 'provider',
            sortable: false,
          },
        ]
      }

      if (this.tab.role === 'Admin') {
        return [
          ...commonColumns,
          {
            label: 'เครดิตคงเหลือ',
            field: 'credit_sandbox',
            type: 'currency',
            filterable: true,
            filterOptions: {
              enabled: true,
              placeholder: '',
            },
          },
          {
            label: 'ยอดขาย',
            field: 'credit_balance',
            type: 'currency',
          },
        ]
      }

      return [...commonColumns]
    },
    cActions() {
      const role = `${this.tab.role}`.toLowerCase()

      if (role === 'user') return [{ keyEmit: 'clickViewUserDetail', label: 'ดูข้อมูลเพิ่มเติม', iconName: 'EyeIcon' }]

      return [
        { keyEmit: 'clickEditDynamicRole', label: 'แก้ไข', iconName: 'EditIcon' },
        { keyEmit: 'clickDeleteDynamicRole', label: 'ลบ', iconName: 'TrashIcon' },
      ]
    },
  },
  methods: {
    addNewUser() {
      this.modalForm = this.cp(this.defaultModalForm)
      this.modalForm.role_data_id = this.roleIdEnumObj[this.tab.role]
      this.$refs['ref-modal-dynamic-form-user-role'].show()
    },
    editUserDynamicRole(row) {
      const fullNameArr = `${row.fullName}`.split(' ')

      const newForm = {
        id: row.id,
        email: row.email,
        name: row.fullName,
        password: '',
        password_confirmation: '',
        role_data_id: row.role_data_id,
        user_profile_data: {
          first_name: fullNameArr[0] ?? '',
          last_name: fullNameArr[1] ?? '',
        },
      }
      this.modalForm = this.cp(newForm)
      this.$refs['ref-modal-dynamic-form-user-role'].show()
    },
    async addOrEdit(bvModal) {
      bvModal.preventDefault()

      const isPassValidate = await this.$refs['ref-form-dynamic-user-role'].validate()
      if (!isPassValidate) return

      const isConfirm = await this.gCheckConfirmV1()

      if (!isConfirm) return

      this.gOpenPageLoading()
      let resp = null

      const requestData = {
        ...this.modalForm,
        name: `${this.modalForm.user_profile_data.first_name} ${this.modalForm.user_profile_data.last_name}`,
      }

      if (this.modalForm.id) {
        resp = await this.api.patchV2(`api/admin/users/${this.modalForm.id}`, requestData, this)
      } else {
        resp = await this.api.postV2('api/admin/users', requestData, this)
      }
      this.$refs['ref-modal-dynamic-form-user-role'].hide()
      this.gClosePageLoading()

      if (resp && resp?.code === 200) {
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.$refs[`ref-table-user-dynamic-role-${this.tab.role}`].reloading()
      } else {
        this.gDisplayToast('ดำเนินการไม่สำเร็จ', resp?.data?.message, 'danger')
      }
    },
    cancelModalForm() {
      this.modalForm = this.cp(this.defaultModalForm)
    },
    async deleteUserDynamicRole(row) {
      const isConfirm = await this.gCheckConfirmV1()

      if (!isConfirm) return
      this.gOpenPageLoading()
      const resp = await this.api.deleteV3(`api/admin/users/${row.id}`, this)
      this.gClosePageLoading()
      if (resp && resp.code === 200) {
        this.gDisplayToast('ลบข้อมูลสำเร็จ')
        this.$refs[`ref-table-user-dynamic-role-${this.tab.role}`].reloading()
      } else {
        this.gDisplayToast('ลบข้อมูลไม่สำเร็จ', resp?.data?.message, 'danger')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
