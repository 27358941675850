var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tab.role !== 'User')?_c('b-btn',{staticClass:"d-flex ml-auto mb-1",attrs:{"variant":"primary"},on:{"click":_vm.addNewUser}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("เพิ่ม "+_vm._s(_vm.tab.title))])],1):_vm._e(),(_vm.tab.role === 'User')?_c('div',{staticClass:"d-flex mb-50"},[_c('b-input',{staticClass:"w-100 mr-50",attrs:{"type":"text","placeholder":"ระบุ UUID ผู้ใช้ทั่วไป เพื่อไปหน้าบัญชีของผู้ใช้"},model:{value:(_vm.paymentUUID),callback:function ($$v) {_vm.paymentUUID=$$v},expression:"paymentUUID"}}),_c('b-btn',{attrs:{"to":{ name: 'userManagement-user-info', params: { userId: _vm.paymentUUID } },"variant":"primary"}},[_vm._v(" ตกลง ")])],1):_vm._e(),_c('table-server-side-v-1-widget',{ref:("ref-table-user-dynamic-role-" + (_vm.tab.role)),attrs:{"url":_vm.cUrl,"columns":_vm.cColumns,"actions":_vm.cActions},on:{"clickViewUserDetail":function (row) { return _vm.$router.push({ name: 'userManagement-user-info', params: { userId: row.id } }); },"clickEditDynamicRole":_vm.editUserDynamicRole,"clickDeleteDynamicRole":_vm.deleteUserDynamicRole},scopedSlots:_vm._u([{key:"country_code",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(row.country_flags_url)?_c('b-img',{staticClass:"mr-50",attrs:{"src":row.country_flags_url,"alt":("" + (row.country_code || '-')),"height":"30"}}):_vm._e(),_c('span',[_vm._v(_vm._s(row.country_code || '-'))])],1)]}}])}),_c('b-modal',{ref:"ref-modal-dynamic-form-user-role",attrs:{"ok-title":"ยืนยัน","cancel-title":"ยกเลิก","hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":"","title":_vm.modalForm.id ? ("แก้ไข " + (_vm.tab.title)) : ("เพิ่ม " + (_vm.tab.title))},on:{"ok":_vm.addOrEdit,"cancel":_vm.cancelModalForm}},[_c('validation-observer',{ref:"ref-form-dynamic-user-role"},[_c('b-form-group',{attrs:{"label":"อีเมล"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.modalForm.email),callback:function ($$v) {_vm.$set(_vm.modalForm, "email", $$v)},expression:"modalForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ชื่อ"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.modalForm.user_profile_data.first_name),callback:function ($$v) {_vm.$set(_vm.modalForm.user_profile_data, "first_name", $$v)},expression:"modalForm.user_profile_data.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"สกุล"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.modalForm.user_profile_data.last_name),callback:function ($$v) {_vm.$set(_vm.modalForm.user_profile_data, "last_name", $$v)},expression:"modalForm.user_profile_data.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"รหัสผ่าน"}},[_c('validation-provider',{attrs:{"rules":"required|min:8","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.modalForm.password),callback:function ($$v) {_vm.$set(_vm.modalForm, "password", $$v)},expression:"modalForm.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ยืนยันรหัสผ่าน"}},[_c('validation-provider',{attrs:{"rules":"required|min:8|confirmed:Password","name":"ยืนยันรหัสผ่าน"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.modalForm.password_confirmation),callback:function ($$v) {_vm.$set(_vm.modalForm, "password_confirmation", $$v)},expression:"modalForm.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }