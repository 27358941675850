<template>
  <div>
    <b-tabs align="left">
      <b-tab v-for="(tab, index) in tabs" :key="tab.role" :title="tab.title" :active="index == 0" lazy>
        <table-dynamic-user-role :tab="tab" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import TableDynamicUserRole from './components/v2/TableDynamicUserRole.vue'

export default {
  components: { TableDynamicUserRole },
  data() {
    return {
      tabs: [
        { title: 'ผู้ใช้ทั่วไป', role: 'User' },
        { title: 'ซัพพอร์ต', role: 'Support' },
        { title: 'การตลาด', role: 'Marketing' },
        { title: 'แอดมิน', role: 'Admin' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
